<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>理事会</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{detail.true_name | sub_name}}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}<i
                            :class="{red:detail.shenpi_num>1}">(第{{ detail.shenpi_num }}次提交)</i></div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">议题：</span
          ><span class="approval-detail-content">{{ detail.title }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">备注：</span
          ><span class="approval-detail-content">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">附件：</span>
                    <div class="approval-detail-file">
                        <div v-for="(file, index) in detail.files" :key="index" class="image-container">
                            <el-image :preview-src-list="detail.files.map(item=>{return item.filePath})" :src="file.filePath"
                                      class="image"></el-image>
                        </div>
                    </div>
                </div>
                <div v-if="detail.state === 1" class="approval-detail-title">
                    <span class="approval-detail-tip">签字地址：</span>
                    <span class="approval-detail-content">
                    <qrcode :margin="0" :size="100" :text="urlImgStr + 'sign/council.html?id=' + pid"></qrcode>
                </span>
                </div>
                <div class="sign-list">
                    <el-table v-if="detail.state === 1" :data="detail.qian"
                        header-row-class-name="table-header">
                        <el-table-column label="序号" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.$index + 1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="签名">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <el-image :preview-src-list="[scope.row.sign]" :src="scope.row.sign"
                                              class="image-sign"></el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="投票">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.status | status }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="电话">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.phone }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.sign_time }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="member_type == 1" label="操作" prop="operate" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <el-popconfirm
                                        title="你确定要删除该签名吗？"
                                        @confirm="delSign(scope.row.id)">
                                        <el-button slot="reference" type="danger">删除</el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <div
                        v-for="item in detail.shenpi"
                        :key="item.id"
                        class="name-container active"
                    >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state === 0 ||  item.state === 3
                    ? 'el-icon-remove'
                    : item.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{
                                        item.true_name
                                    }}<i v-if="item.state != 0">({{ item.state | sp_state }})</i>
                                </div>
                                <div v-if="item.sp_time" class="info-time">
                                    {{ item.sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="item.state === 0" class="info-desc">
                                {{ iself(item) ? "我（" : "" }}{{
                                    item.state | sp_state
                                }}{{ iself(item) ? "）" : "" }}
                            </div>
                            <div v-if="item.state !== 0" class="info-desc">
                                {{ item.sp_remark }}
                            </div>
                        </div>
                    </div>
                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name"
                                >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 0 && (detail.state === 1 || detail.state === 2)"
                    class="approval-btn-del margin-right"
                    @click="toRevoke()"
                >删除
                </el-button
                >
                <!--<router-link :to="'/home/subSign?edit=1&id=' + pid">-->
                <!--<el-button-->
                <!--class="approval-btn-left"-->
                <!--v-if="member_type == 1 && detail.state == 0"-->
                <!--&gt;修改-->
                <!--</el-button-->
                <!--&gt;-->
                <!--</router-link>-->
                <router-link :to="'/home/subCouncil?id=' + pid">
                    <el-button
                        v-if="member_type == 1 && detail.state == 2"
                        class="approval-btn-left"
                    >重新提交
                    </el-button
                    >
                </router-link
                >
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)"
                >拒绝
                </el-button
                >
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)"
                >通过
                </el-button
                >
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/councilDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import config from "@/config/index";
import Qrcode from 'vue-qr'
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";

export default {
    components: {
        qrcode: Qrcode
    },
    name: "approvalDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            detail: {},
            urlImgStr: config.domain
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 20, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 20, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .del_toppics({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        delSign(id) {
            this.$api.apiContent
                .del_council_sign({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init()
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getCouncil({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.qian.map((item) => {
                        item.sign = config.urlImgStr + item.sign
                        item.sign_time = this.$tools.ZHDate(
                            new Date(item.sign_time * 1000),
                            1
                        );
                    });
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })

                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isApprovaler = false;
            this.editVisible = false;
            this.requestState = false
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = true;
                    }
                }
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },

    filters: {
        filePath(url) {
            return webUrl(url)
        },
        status(type) {
            switch (Number(type)) {
                case 1:
                    return "同意";
                case 2:
                    return "反对";
                case 3:
                    return "弃权";
                default:
                    return "弃权";
            }
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 1:
                    return "已通过";
                case 2:
                    return "已驳回";
                default:
                    return "已删除";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000),6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }

            .approval-btn-del {
                width: 180px;
                margin-left: 20px;
                background: #e70305;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .info-file {
                                font-size: 16px;
                                color: #3b77e7;
                                margin-top: 12px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    flex: 7;
                    flex-shrink: 1;
                    display: flex;
                    flex-wrap: wrap;
                    .image-container {

                        &:not(:nth-child(4n+1)){
                            margin-left: 20px;
                        }
                        width: 150px;
                        height: 150px;
                        position: relative;
                        border: 1px solid #e1e1e1;
                        margin-bottom: 20px;

                        .image {
                            width: 100%;
                            height: 100%;
                        }

                        .del {
                            position: absolute;
                            right: 1px;
                            top: 1px;
                            background: #e4393c;
                            border-radius: 20px;
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            color: #FFFFFF;
                            text-align: center;
                            cursor: pointer;
                        }
                    }

                }

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }

    .sign-list {
        width: 100%;
        padding: 34px 28px;

        .table-item {
            text-align: center;
            font-size: 16px;
            color: #666666;

            .image-sign {
                width: 150px;
            }
        }
    }
}
</style>
